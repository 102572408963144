import axios from 'axios'
import router from './../router'
import store from './../store'
import CONFIG from './config.js'

// import { Message } from 'element-ui'
import { Message } from 'iview';
// import debounce from 'lodash.debounce';

const API = CONFIG.CONFIG.API;

// const showLoginTip = debounce(() => {
//     Message.warning('尚未登陆，请登陆')
// }, 300);


axios.interceptors.request.use(
    config => {
        if (localStorage.getItem('Authorization')) {
            config.headers.Authorization = `Bearer ${localStorage.getItem('Authorization')}`;
        }
        config.headers.ContentType = 'application/json';
        config.url = config.url.replace('/api/', 'api/');
        let root = window.location.pathname;
        let last = root[root.length - 1];
        if (last === '/') {
            config.url = root + config.url;
        } else {
            config.url = root + '/' + config.url;
        }

        return config;
    },
    err => {
        return Promise.reject(err)
    }
)

axios.interceptors.response.use(
    response => {
        // 如果是矿池相关接口, 如果status是4, 则代表未激活, 直接跳转
        if (response.config.url.indexOf('poolnode') > -1) {
            if (response.data.status == 4) {
                Message.warning('尚未激活节点, 请先激活!');

                router.push({
                    path: '/system/regpool'
                })
            }
        }

        return response
    },
    error => {
        if (!error.response) {
            return null;
        }

        // 403未登录
        if (error.response.status == 403 || error.response.status == 401) {
            router.push({
                path: '/login'
            })

            // Message.warning('尚未登陆，请登陆')
            // showLoginTip();

            return Promise.reject(null);
        } else {
            // 这里的接口报错不要提示出来
            if (
                error.config.url == API.getRMSConfig ||
                error.config.url == API.getOriginConfig ||
                error.config.url == API.ping ||
                error.config.url == API.setRoute ||
                error.config.url == API.p_set_web_route ||
                error.config.url == API.p_set_web_port
            ) {
                console.log(`忽略接口报错:${error.config.url}`)
            } else {
                Message.error(`${error.request.responseURL} -> ${error.response.statusText}：${error.response.status} [MESSAGE]: ${error.response.data.message}`);
            }

            return Promise.reject(error)
        }
    }
)

export default axios
