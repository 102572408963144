import axios from 'axios'
import CONFIG from '@/script/config.js'

const $CONFIG = CONFIG.CONFIG

// 合并端口以及节点name的数据
const mergePoint = (endpoint, ports) => {

    ports.forEach(port => {
        let portAddressId = port.server.pool_address;
        endpoint.forEach(point => {
            if (Number(portAddressId) == point.id) {
                port.server._address_name = point.name
            }
        })

        // 顺便加入个默认的ping填充
        port.server._ping = '获取中...'
    });
}

const state = () => ({
    projectInfo: {
    },
    projectCoinFee: [],
    projectPort: [],
    webPort: 0,
    webRoute: '',
    poolEnable: true,
    tlsEnable: false,
    defaultCert: true
})

const getters = {
    getProjectInfo: (state, getters, rootState) => {
        return state.projectInfo
    },
    getProjectCoinFee: (state, getters, rootState) => {
        return state.projectCoinFee
    },
    getProjectPort: (state, getters, rootState) => {
        return state.projectPort
    },
    getWebPort: (state, getters, rootState) => {
        return state.webPort
    },
    getWebRoute: (state, getters, rootState) => {
        return state.webRoute
    },
    getPoolEnable: (state, getters, rootState) => {
        return state.poolEnable
    },
    getTlsEnable: (state, getters, rootState) => {
        return state.tlsEnable
    },
    getDefaultCert: (state, getters, rootState) => {
        return state.defaultCert
    }
}

const actions = {
    updateDefaultCert({state, commit}, callback) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: $CONFIG.API.p_get_cert_type
            }).then(res => {
                if (res.status == 200 && res.data.status == 0) {
                    commit('setDefaultCert', res.data.data)
                    callback && callback(res.data.data)
                } else {
                    reject('get defualtCert Info ERROR');
                }
            }).catch(e => {
                reject(e)
            })
        })
    },
    updateWebRoute({state, commit}, callback) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: $CONFIG.API.p_get_web_route
            }).then(res => {
                if (res.status == 200 && res.data.status == 0) {
                    commit('setWebRoute', res.data.data)

                    callback && callback(res.data.data)
                } else {
                    reject('get Project Info ERROR');
                }
            }).catch(e => {
                reject(e)
            })
        })
    },
    updateWebPort({state, commit}, callback) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: $CONFIG.API.p_get_web_port
            }).then(res => {
                if (res.status == 200 && res.data.status == 0) {
                    commit('setWebPort', res.data.data)
                    
                    callback && callback(res.data.data)
                } else {
                    reject('get WebPOrt Info ERROR');
                }
            }).catch(e => {
                reject(e)
            })
        })
    },
    updateProjectInfo({state, commit}) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: $CONFIG.API.p_get_node_info
            }).then(res => {
                if (res.status == 200 && res.data.status == 0) {
                    commit('setProjectInfo', res.data.data)
                } else {
                    reject('get Project Info ERROR');
                }
            }).catch(e => {
                reject(e)
            })
        })
    },
    updatePoolEnable({state, commit}, callback) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: $CONFIG.API.p_get_enable
            }).then(res => {
                if (res.status == 200 && res.data.status == 0) {
                    commit('setPoolEnable', res.data.data)
                } else {
                    reject('get Pool Enable ERROR');
                }
                
                callback && callback()
            }).catch(e => {
                reject(e)
            })
        })
    },
    updateTlsEnable({state, commit}, callback) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: $CONFIG.API.p_get_tls
            }).then(res => {
                if (res.status == 200 && res.data.status == 0) {
                    commit('setTlsEnable', res.data.data)
                } else {
                    reject('get TLS Enable ERROR');
                }
                
                callback && callback()
            }).catch(e => {
                reject(e)
            })
        })
    },
    updateProjectPort({state, commit}) {
        return new Promise((resolve, reject) => {
            axios({
                methods: 'get',
                url: $CONFIG.API.p_get_endpoint
            }).then(res => {
                if (res.status == 200 && res.data.status == 0) {
                    return res.data.data
                } else {
                    alert('获取节点信息失败!!!')
                    return []
                }
            }).then(endpoint => {
                axios({
                    method: 'get',
                    url: $CONFIG.API.p_get_port
                }).then(res => {
                    if (res.status == 200) {
                        mergePoint(endpoint, res.data)
                        commit('setProjectPort', res.data)

                        // 获取每个端口的ping
                        for (let i = 0; i < res.data.length; i++) {
                            ((port, commit, index) => {
                                let pool_address = port.server.pool_address;
                                axios({
                                    method: 'get',
                                    url: $CONFIG.API.p_ping_endpoint.replace('{id}', pool_address)
                                }).then(pingResult => {
                                    if (pingResult.status == 200 && pingResult.data.status == 0) {
                                        commit('setPortPing', {
                                            index,
                                            ping: pingResult.data.data + 'ms'
                                        })
                                    } else {
                                        commit('setPortPing' ,{
                                            index,
                                            ping: '通信失败'
                                        })
                                    }
                                })
                            })(res.data[i], commit, i)
                        }
                    } else {
                        reject('get Project Port ERROR');
                    }
                }).catch(e => {
                    reject(e)
                })
            })
        })
    }
}

const mutations = {
    setProjectInfo (state, data) {
        state.projectInfo = data
        state.projectCoinFee = data.config
    },
    setProjectPort (state, data) {
        state.projectPort = data
    },
    setWebPort (state, data) {
        state.webPort = data
    },
    setWebRoute (state, data) {
        state.webRoute = data
    },
    setPortPing (state, data) {
        state.projectPort[data.index].server._ping = data.ping
    },
    setPoolEnable (state, data) {
        if (data === 0) {
            state.poolEnable = false
        } else {
            state.poolEnable = true
        }
    },
    setTlsEnable (state, data) {
        if (data == 0) {
            state.tlsEnable = false
        } else {
            state.tlsEnable = true
        }
    },
    setDefaultCert (state, data) {
        if (data == 0) {
            state.defaultCert = true
        } else {
            state.defaultCert = false
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}