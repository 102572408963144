import { render, staticRenderFns } from "./AuthConfig.vue?vue&type=template&id=61ff5f0d&scoped=true"
import script from "./AuthConfig.vue?vue&type=script&lang=js"
export * from "./AuthConfig.vue?vue&type=script&lang=js"
import style1 from "./AuthConfig.vue?vue&type=style&index=1&id=61ff5f0d&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.21_css-loader@6.11.0_webpack@5.91.0__lodash@4.17.21__rfscy6xveyhe2qrtzyygtrm2uu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61ff5f0d",
  null
  
)

export default component.exports