<template>
    <div class="handle-port">
        <Modal class-name="handle-port-model" v-model="visable" v-show="visable" :mask-closable="true" :width="100"
            title="添加端口" :footer-hide="true">
            <p class="tips">您正在为<span style="color: red;">所有 {{getProjectInfo.name}} 节点添加端口</span>, 相同节点服务器将自动启用此端口, 请确保本服务器或其他服务器端口不被占用。</p>
            <Form :model="form" :label-width="80" v-loading="loading">
                <FormItem label="币种">
                    <Select v-model="form.coin" placeholder="请选择币种" @on-change="coinChange" :transfer="true">
                        <Option value="BTC">BTC</Option>
                    </Select>
                </FormItem>
                <FormItem label="端口号">
                    <InputNumber :max="65535" :min="1" v-model="form.port" placeholder="请输入端口号" style="width: 100%;"></InputNumber>
                </FormItem>
                <FormItem label="端口协议">
                    <Select v-model="form.deal" placeholder="请选择监听协议" :transfer="true">
                        <Option :value="0">TCP</Option>
                        <Option :value="1">SSL / TLS</Option>
                        <Option :value="3">TTS（TCP / SSL双协议）</Option>
                        <Option :value="2">RMS</Option>
                    </Select>
                </FormItem>
                <FormItem label="地区">
                    <Select v-model="form.areaID" placeholder="请选择合适的地区" @on-change="coinChange" :transfer="true">
                        <Option v-for="item in areaTable" :value="item.id" :key="item.id">
                            {{ item.name }}
                        </Option>
                    </Select>
                </FormItem>
                <FormItem label="备注">
                    <Input v-model="form.name" placeholder="端口备注, 非必填"></Input>
                </FormItem>
                <FormItem label="官网展示挖矿地址">
                    <RadioGroup v-model="form.public">
                        <Radio :label="1">显示</Radio>
                        <Radio :label="0">隐藏</Radio>
                    </RadioGroup>
                </FormItem>
            </Form>
            <Button type="primary" style="width: 100%;" :loading="saveLoading" @click="save">保存</Button>
        </Modal>
    </div>
</template>
<script>

// import TermCon from '@/components/TermCon.vue'
import {
    mapState,
    mapActions,
    mapGetters
} from 'vuex'

export default {
    data() {
        return {
            visable: false,
            loading: false,
            form: {
                coin: '',
                areaID: '',
                port: null,
                public: 1,
                deal: 0,
                name: ''
            },
            area: {},
            areaTable: [],
            saveLoading: false
        }
    },
    components: {
        // TermCon
    },
    computed: {
        ...mapGetters('pool', {
            getProjectInfo: 'getProjectInfo',
            getProjectCoinFee: 'getProjectCoinFee'
        })
    },
    mounted() {
    },
    methods: {
        ...mapActions('pool', {
            updateProjectPort: 'updateProjectPort'
        }),
        show() {
            this.visable = true

            this.getArea();
        },
        coinChange() {
            this.updateAreaTableByActive()
        },
        updateAreaTableByActive() {
            this.areaTable = []

            let activeCoin = this.form.coin;

            if (!activeCoin) {
                return
            }
            
            let target = this.area[activeCoin];
            this.areaTable = target
        },
        getArea() {
            this.area = [];

            this.loading = true

            this.axios({
                methods: 'get',
                url: this.$API.p_get_endpoint,
            }).then(res => {
                if (res.status == 200 && res.data.status == 0) {
                    let data = res.data.data;

                    for (let i = 0; i < data.length; i++) {
                        let target = data[i];
                        
                        if (!this.area[target.coin]) {
                            this.area[target.coin] = []
                        }

                        this.area[target.coin].push(target)
                    }

                    this.updateAreaTableByActive()

                    this.loading = false
                } else {
                    this.$Message.error('获取地区数据失败。')
                }
            })
        },
        hide() {
            this.visable = false
        },
        save() {
            if (!this.form.coin || !this.form.port || !this.form.areaID) {
                this.$Message.error('请填写完整信息。')
                return
            }

            this.saveLoading = true;

            this.axios({
                method: 'post',
                url: this.$API.p_new_port,
                data: {
                    "port": this.form.port,
                    "name": this.form.name,
                    "currency": this.form.coin,
                    "nc": 0,
                    "limit_connections": 60000,
                    "pool_address": JSON.stringify(this.form.areaID),
                    "connect_mode": 0,
                    "pool_address2": null,
                    "connect_mode2": 1,
                    "mode": this.form.public,
                    "pattern_addr": "",
                    "replace_addr": "",
                    "wallets": [],
                    "protocol": this.form.deal,
                    "category": "poolnode"
                }
            }).then(res => {
                this.saveLoading = false
                
                if (res.status == 200) {
                    this.$Message.success('创建成功！所有此节点下的服务器均增加这个端口。')
                    this.updateProjectPort()
                } else {
                    this.$Message.error('端口创建失败！')
                }

                this.hide()
            })
        }
    }
}
</script>

<style lang="scss">
@media screen and (max-width: $mdWidth) {
    .handle-port-model {
        .md-w {
            margin-bottom: 10px;
        }
    }
}

@media screen and (max-width: $hideMenuWidth) {
    .handle-port-model {
        width: 100% !important;
        max-width: 100% !important;
    }
}

.handle-port-model {
    margin: 0 auto;
    width: 350px;
    overflow: hidden !important;
    overflow-y: auto !important;

    .free-box{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .tips{
        margin-bottom: 20px;
    }
}
</style>
