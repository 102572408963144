import { render, staticRenderFns } from "./SetPumpByWallet.vue?vue&type=template&id=1c89b82f"
import script from "./SetPumpByWallet.vue?vue&type=script&lang=js"
export * from "./SetPumpByWallet.vue?vue&type=script&lang=js"
import style0 from "./SetPumpByWallet.vue?vue&type=style&index=0&id=1c89b82f&prod&lang=scss"
import style1 from "./SetPumpByWallet.vue?vue&type=style&index=1&id=1c89b82f&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.21_css-loader@6.11.0_webpack@5.91.0__lodash@4.17.21__rfscy6xveyhe2qrtzyygtrm2uu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports